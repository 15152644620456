import React from 'react';
import BtcPrice from './BtcPrice';

import { Container } from 'reactstrap';

function App() {
  return (
    <div>
      <Container fluid style={{textAlign: 'center'}}>
        <h2 style={{color: 'grey'}}>Bitcoin Price</h2>
      </Container>
     <BtcPrice />
    </div>
  );
}

export default App;
